<template>
  <div class="container">
    <div class="header">
      <div class="header_logo">
        <img src="@/assets/imgs/mcc_logo.svg" />
        <span class="logo_text">MCC Singapore Enterprise</span>
      </div>
      <div class="header_data">
        <div class="header_data_left">
          <div class="left_title">Awards and Recognition</div>
          <div class="left_content">
            These awards and recognition serve as a testament to the exceptional
            talent, expertise, and dedication of MCC Singapore. By joining us,
            you'll have the opportunity to work alongside a highly esteemed team
            that consistently strives for excellence in our works. We believe in
            providing our professionals with the resources and support they need
            to drive meaningful impact, shape our organizational culture, and
            create a workplace where every individual can thrive.
          </div>
          <div class="left_content_weight">
            We invite you to explore the exciting career opportunities within
            our company, and be part of an award-winning team that is committed
            to unlocking the full potential of our employees and re-define
            success with us.
          </div>
        </div>
        <div class="header_data_right">
          <img src="@/assets/imgs/hr/awardsAll/awardsAll_logo.svg" />
        </div>
      </div>
    </div>
    <div class="content">
      <div
        @click="openNew(item.linkPC)"
        class="card"
        v-for="(item, i) in list"
        :key="i"
        :class="{ ged: (i + 1) % 3 == 0 }"
      >
        <div style="width: 150px; height: 150px; margin: auto">
          <img
            style="width: 100%; height: 100%; object-fit: contain"
            v-if="item.iconList.length > 0"
            :src="item.iconList[0].link"
            alt=""
          />
        </div>

        <div class="card_title">{{ item.title | filterStr }}</div>
        <div class="card_plane">{{ item.channel | filterStr }}</div>
        <div class="card_year">{{ item.year }}</div>
        <div class="card_description">
          <!-- {{ item.description }} -->
          <p v-html="item.description.replace(/\n/g, '<br>')"></p>
        </div>
      </div>
    </div>
    <div class="blueBlock" style="height: 20px; margin-top: 56px"></div>
    <Footer @openQrcode="openQrcode"></Footer>
    <div class="qrcodeImg" v-if="showQrcode">
      <div class="qrcode_header">
        <img
          src="@/assets/imgs/hr/icon-close.svg"
          @click="() => (showQrcode = !showQrcode)"
        />
      </div>
      <div class="qrcode_content">
        <img src="@/assets/imgs/hr/wxqrcode.svg" alt="" />
        <div class="qrcode_text">
          Scan QR code with WeChat
          <br />

          App to connect
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Footer from "./components/footer.vue";
import { minxin } from "@/mixin.js";
export default {
  mixins: [minxin],
  components: {
    Footer,
  },
  data() {
    return {
      showQrcode: false,
      list: [],
    };
  },
  mounted() {
    this.getList();
  },
  filters: {
    filterStr(e) {
      if (e.length <= 30) {
        return e;
      }
      return e.substr(0, 30) + "...";
    },
  },
  methods: {
    openQrcode(e) {
      this.showQrcode = e;
    },
    async getList() {
      let response = await this.$http.get(`${this.$url.hr.awartList}?current=1&&size=10000`);
      if (response.code == 200) {
        this.list = response.data.records;
      } else {
        this.list = [];
      }
      console.log(response);
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  height: auto;
  font-family: Arial;
  padding-top: 4.6rem;
  .header {
    width: 1100px;
    height: auto;
    margin: auto;
    .header_logo {
      // margin-top:146px ;
      width: 100%;
      height: auto;
      text-align: left;
      .logo_text {
        margin-left: 24px;
        background: var(
          --primary-linear,
          linear-gradient(90deg, #3574af 0%, #47adff 100%)
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: Arial;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        letter-spacing: -0.27px;
      }
    }
    .header_data {
      width: 100%;
      height: 436px;
      margin-top: 40px;
      // background-color: bisque;
      display: flex;
      flex-direction: row;
      align-items: center;
      .header_data_left {
        width: 560px;
        height: auto;
        margin-right: 20px;
        .left_title {
          width: 100%;

          font-size: 36px;
          font-style: normal;
          line-height: 94px;
          height: 94px;
          font-weight: 700;
          letter-spacing: -0.54px;
          background: var(
            --primary-linear,
            linear-gradient(90deg, #3574af 0%, #47adff 100%)
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .left_content {
          width: 100%;
          margin-top: 24px;
          word-break: keep-all;
          color: var(--mcc-dark, #707070);
          font-family: Arial;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 24px */
          letter-spacing: -0.24px;
        }
        .left_content_weight {
          width: 100%;
          margin-top: 24px;
          width: 100%;
          word-break: keep-all;
          color: var(--mcc-dark, #707070);
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 30px; /* 30px */
          letter-spacing: -0.3px;
        }
      }
    }
  }
  .content {
    width: 1100px;
    height: auto;
    margin: auto;
    margin-top: 173.21px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .ged {
      margin-right: 0px !important;
    }
    .card {
      width: 356px;
      height: 480px;
      padding: 32px 16px 42px 16px;
      border-radius: 15px;
      background: url(<path-to-image>), lightgray 50% / cover no-repeat, #fff;
      box-shadow: 6px 9px 20px 0px rgba(0, 0, 0, 0.1);
      margin-bottom: 32px;
      text-align: center;
      box-sizing: border-box;
      margin-right: 16px;
      .card_icon {
        width: 150px;
        height: 150px;
      }
      .card_title {
        color: var(--mcc-primary-01, #0055a5);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 19.2px */
        letter-spacing: -0.24px;
        margin-top: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .card_plane {
        color: var(--mcc-dark, #707070);
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 12px */
        letter-spacing: -0.18px;
        margin-top: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .card_year {
        color: var(--mcc-dark, #707070);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 14.4px */
        letter-spacing: -0.18px;
        margin-top: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .card_description {
        color: var(--mcc-dark, #707070);
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 18px */
        letter-spacing: -0.18px;
        margin-top: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 10;
        -webkit-box-orient: vertical; //显示行数
      }
    }
  }
}
.blueBlock {
  width: 100%;
  background: var(
    --primary-linear,
    linear-gradient(90deg, #0055a5 0%, #47adff 100%)
  );
}
.qrcodeImg {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  position: fixed;
  padding: 24px;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.9);
  .qrcode_header {
    width: 100%;
    text-align: right;
  }
  .qrcode_content {
    border-radius: 8px;
    margin: auto;
    width: 326px;
    text-align: center;
    height: auto;
    background-color: #ffffff;
    margin-top: calc(50vh - 300px);
    padding: 24px;
    box-sizing: border-box;

    .qrcode_text {
      background: var(
        --primary-linear,
        linear-gradient(90deg, #3574af 0%, #47adff 100%)
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-align: center;
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 16.8px; /* 16.8px */
      letter-spacing: -0.21px;
      margin-top: 16px;
    }
  }
}
</style>